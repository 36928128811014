<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>bar_chart</md-icon>
            <h3 class="pl-2 text-uppercase">Performance Checking</h3>
          </div>
          <SwitchComponent :items="[{id:1, name: 'List'}, {id: 2, name: 'Graph'}]" />
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
          <div class="inline-flex flex-wrap justify-sm-center justify-xs-center">
            <SelectComponent class="w-64 m-2" />
            <SelectComponent class="w-64 m-2" />
          </div>
          <SearchBox class="w-64 p-2 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="performanceChecking" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="TASK ID">{{ item.taskId }}</md-table-cell>
            <md-table-cell md-label="TASK NAME">{{ item.taskName }}</md-table-cell>
            <md-table-cell md-label="ASSIGN BY">{{ item.assignBy }}</md-table-cell>
            <md-table-cell md-label="ASSIGN AT">{{ item.assignAt }}</md-table-cell>
            <md-table-cell md-label="DEADLINE">{{ item.deadline }}</md-table-cell>
            <md-table-cell md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <md-table-cell md-label="POINT">{{ item.point }}</md-table-cell>
            <span class="action">
              <md-icon
                class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"
                @click.native="$emit('on-click', {item: item, component: 'showDetailsPerformanceChecking'})"
              >visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, SwitchComponent, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import data from "@/data/crm/data"
export default {
  name: "Todo-List",
  components: {
    Card,
    Table,
    CardBody,
    SelectComponent,
    SearchBox,
    SwitchComponent
  },
  data() {
    return {
      performanceChecking: data.performanceChecking
    }
  }
};
</script>
