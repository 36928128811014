const awardPoints = [];

for (let i = 0; i < 15; i++) {
    awardPoints.push({
        id: i,
        taskId: 'T12'+i,
        taskName: 'Question Creation',
        assignBy: 'Kazi Shahin',
        assignAt: '15 sep, 2020',
        deadline: '20 sep, 2020',
        status: 'Complete',
        point: 75
    })
}

const performanceChecking = [];

for (let i = 0; i < 15; i++) {
    performanceChecking.push({
        id: i,
        taskId: 'T12'+i,
        taskName: 'Question Creation',
        assignBy: 'Kazi Shahin',
        assignAt: '15 sep, 2020',
        deadline: '20 sep, 2020',
        status: 'Complete',
        point: '90%'
    })
}


export default {
    awardPoints,
    performanceChecking
}